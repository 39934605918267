/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
if (window.Element && !Element.prototype.closest) {
  Element.prototype.closest =
    function (s) {
      var matches = (this.document || this.ownerDocument).querySelectorAll(s),
        i,
        el = this;
      do {
        i = matches.length;
        while (--i >= 0 && matches.item(i) !== el) { }
      } while ((i < 0) && (el = el.parentElement));
      return el;
    };
}
if (!Array.from) {
  Array.from = (function () {
    var toStr = Object.prototype.toString;
    var isCallable = function (fn) {
      return typeof fn === 'function' || toStr.call(fn) === '[object Function]';
    };
    var toInteger = function (value) {
      var number = Number(value);
      if (isNaN(number)) { return 0; }
      if (number === 0 || !isFinite(number)) { return number; }
      return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
    };
    var maxSafeInteger = Math.pow(2, 53) - 1;
    var toLength = function (value) {
      var len = toInteger(value);
      return Math.min(Math.max(len, 0), maxSafeInteger);
    };

    // The length property of the from method is 1.
    return function from(arrayLike/*, mapFn, thisArg */) {
      // 1. Let C be the this value.
      var C = this;

      // 2. Let items be ToObject(arrayLike).
      var items = Object(arrayLike);

      // 3. ReturnIfAbrupt(items).
      if (arrayLike == null) {
        throw new TypeError("Array.from requires an array-like object - not null or undefined");
      }

      // 4. If mapfn is undefined, then let mapping be false.
      var mapFn = arguments.length > 1 ? arguments[1] : void undefined;
      var T;
      if (typeof mapFn !== 'undefined') {
        // 5. else
        // 5. a If IsCallable(mapfn) is false, throw a TypeError exception.
        if (!isCallable(mapFn)) {
          throw new TypeError('Array.from: when provided, the second argument must be a function');
        }

        // 5. b. If thisArg was supplied, let T be thisArg; else let T be undefined.
        if (arguments.length > 2) {
          T = arguments[2];
        }
      }

      // 10. Let lenValue be Get(items, "length").
      // 11. Let len be ToLength(lenValue).
      var len = toLength(items.length);

      // 13. If IsConstructor(C) is true, then
      // 13. a. Let A be the result of calling the [[Construct]] internal method of C with an argument list containing the single item len.
      // 14. a. Else, Let A be ArrayCreate(len).
      var A = isCallable(C) ? Object(new C(len)) : new Array(len);

      // 16. Let k be 0.
      var k = 0;
      // 17. Repeat, while k < len… (also steps a - h)
      var kValue;
      while (k < len) {
        kValue = items[k];
        if (mapFn) {
          A[k] = typeof T === 'undefined' ? mapFn(kValue, k) : mapFn.call(T, kValue, k);
        } else {
          A[k] = kValue;
        }
        k += 1;
      }
      // 18. Let putStatus be Put(A, "length", len, true).
      A.length = len;
      // 20. Return A.
      return A;
    };
  }());
}

(function($) {
  
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var hasClass = function(el, selector) {
    return (el.matches || el.matchesSelector || el.msMatchesSelector || el.mozMatchesSelector || el.webkitMatchesSelector || el.oMatchesSelector).call(el, selector);
  };
  var Sage = {
    // All pages
    'common': {
      init: function() {
        var dropdowns      = Array.from(document.querySelectorAll('.dropdown'));
        var dropMenus      = Array.from(document.querySelectorAll('.dropdown-menu'));

        //Mobile Menu functionality
        dropdowns.forEach(function (dropdown) {
          dropdown.addEventListener('click', function (e) {
            var that = e.target.parentElement;
            if (!hasClass(that, '.third-level-menu') ) {
              //add class to this element
              that.querySelector('.dropdown-menu').classList.toggle('open-sublevel');
              //remove from the others elements
              dropMenus.filter(function(elements){
                return elements !== that.querySelector('.dropdown-menu');
              }).forEach(function(el){
                el.classList.remove('open-sublevel');
              });
            }
            else{
              e.preventDefault();
              //add class to this element
              if (!hasClass(that,'.open-sublevel')){
                // console.log('true');
                that.querySelector('.dropdown-menu').classList.toggle('open-sublevel');
                that.classList.toggle('open');
              }
              else{
                // console.log('false');
                that.classList.remove('open');
                that.querySelector('.dropdown-menu').classList.remove('open-sublevel');
              }
            }
          });
        });
        //Open mobile menu
        document.querySelector('.js-open').addEventListener('click',function(e){
          document.getElementsByTagName('body')[0].classList.add('mobile-menu-is-open');
        });
        //Close mobile menu
        document.querySelector('.js-close').addEventListener('click',function(e){
          document.getElementsByTagName('body')[0].classList.remove('mobile-menu-is-open');
        });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        var booksWrapper = document.getElementById('books');
        var bgLogo       = booksWrapper.querySelector('.bg-logo');
        var booksImg     = booksWrapper.querySelector('.books-img');
        
        var tweenLogo    = TweenMax.to(bgLogo,1,{y: "-100%",ease: Linear.easeOut});
        var tweenBooks   = TweenMax.from(booksImg,0.5,{y: "-50%",ease: Power4.easeOut});

        var controller = new ScrollMagic.Controller({loglevel: 0});
       
        var logoScene  = new ScrollMagic.Scene({
                          triggerHook:'onEnter',
                          triggerElement:'#books',
                          duration:1200,
                          loglevel:0
                        })
                        // .addIndicators()
                        .setTween(tweenLogo)
                        .addTo(controller);
        
        var booksScene = new ScrollMagic.Scene({
                          triggerHook:'onEnter',
                          triggerElement:'#books',
                          duration:1200,
                          loglevel:0
                        })
                        // .addIndicators()
                        .setTween(tweenBooks)
                        .addTo(controller);

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'contact': {
      init: function() {
        var inputs = Array.from(document.querySelectorAll('.wpcf7-form-control'));
        
        var isFocus = function(e){
          var container = e.target.closest('.input__container');
          
          if(hasClass(container,'.is-focused')){
            container.classList.remove('is-focused');
          }
          else{
            container.classList.add('is-focused');
          }

        };

        var hasValue = function(e){
          var container = e.target.closest('.input__container');

          if(e.target.value) {
            container.classList.add('is-active');
          }
          else{
            container.classList.remove('is-active');
          }
        };

        inputs.forEach(function(input) {
          input.addEventListener('focus',isFocus);
          input.addEventListener('blur',isFocus);
          input.addEventListener('keyup', hasValue);
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
